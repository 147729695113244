<template>
  <div class="our-team">
    <r-row>
      <div class="head">
        <h3>MEET OUR TEAM</h3>
        <p>We are beautiful and smart</p>
        <span></span>
      </div>
    </r-row>

    <r-row>
      <el-row :gutter="35">
        <r4-1
          ><div class="item">
            <img src="../../assets/images/t1.jpg" alt="" srcset="" />

            <div class="team-member">
              <h4>John DOE</h4>
              <p>Executive Director</p>
            </div>
            <a href="#" class="author">j.doe@ashley.com</a>
            <div class="btns">
              <span class="btn">
                <i class="bi bi-facebook"></i>
              </span>
              <span class="btn"><i class="bi bi-twitter"></i></span>
              <span class="btn">
                <i class="bi bi-google"></i>
              </span>
              <span class="btn">
                <i class="bi bi-github"></i>
              </span>
              <span class="btn"><i class="bi bi-three-dots"></i></span>
            </div>
          </div>
        </r4-1>
        <r4-1
          ><div class="item">
            <img src="../../assets/images/t2.jpg" alt="" srcset="" />
            <div class="team-member">
              <h4>John DOE</h4>
              <p>Executive Director</p>
            </div>
            <a href="#" class="author">j.doe@ashley.com</a>
            <div class="btns">
              <span class="btn">
                <i class="bi bi-facebook"></i>
              </span>
              <span class="btn"><i class="bi bi-twitter"></i></span>
              <span class="btn">
                <i class="bi bi-google"></i>
              </span>
              <span class="btn">
                <i class="bi bi-github"></i>
              </span>
              <span class="btn"><i class="bi bi-three-dots"></i></span>
            </div></div
        ></r4-1>
        <r4-1
          ><div class="item">
            <img src="../../assets/images/t3.jpg" alt="" srcset="" />
            <div class="team-member">
              <h4>John DOE</h4>
              <p>Executive Director</p>
            </div>
            <a href="#" class="author">j.doe@ashley.com</a>
            <div class="btns">
              <span class="btn">
                <i class="bi bi-facebook"></i>
              </span>
              <span class="btn"><i class="bi bi-twitter"></i></span>
              <span class="btn">
                <i class="bi bi-google"></i>
              </span>
              <span class="btn">
                <i class="bi bi-github"></i>
              </span>
              <span class="btn"><i class="bi bi-three-dots"></i></span>
            </div></div
        ></r4-1>
        <r4-1
          ><div class="item">
            <img src="../../assets/images/t4.jpg" alt="" srcset="" />
            <div class="team-member">
              <h4>John DOE</h4>
              <p>Executive Director</p>
            </div>
            <a href="#" class="author">j.doe@ashley.com</a>
            <div class="btns">
              <span class="btn">
                <i class="bi bi-facebook"></i>
              </span>
              <span class="btn"><i class="bi bi-twitter"></i></span>
              <span class="btn">
                <i class="bi bi-google"></i>
              </span>
              <span class="btn">
                <i class="bi bi-github"></i>
              </span>
              <span class="btn"><i class="bi bi-three-dots"></i></span>
            </div></div
        ></r4-1>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import RRow from "../../components/RRow.vue";
export default {
  components: { RRow },
  name: "OurTeam",
};
</script>

<style lang="scss">
.our-team {
  padding: 50px 0;
  background-color: #fff;
  .head {
    text-align: center;
    margin-bottom: 2rem;
    h3 {
      color: #333333;
      font-size: 3.4em;
      font-weight: 300;
      text-transform: uppercase;
      // letter-spacing: 5px;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      color: #888888;
      font-size: 1.65em;
      font-weight: 300;
      margin: 0.5em 0 0.7em 0;
    }
    span {
      height: 1px;
      width: 10%;
      background: #4fafc2;
      color: #4fafc2;
      display: inline-block;
    }
  }

  .item {
    margin: 2rem 0;
    img {
      width: 100%;
    }
    .team-member {
      text-align: center;
      padding: 1.6em 0;
      background: #4fafc2;
      color: #fff;
      h4 {
        color: #fff;
        font-size: 1.3em;
        font-weight: 500;
        margin-bottom: 0.3em;
      }

      p {
        color: #fff;
        font-size: 1em;
        font-weight: 300;
        margin-bottom: 0.1em;
      }
    }

    a.author {
      color: #4fafc2;
      font-size: 1em;
      font-weight: 400;
      display: block;
      text-align: center;
      margin-top: 1.2em;
      &:hover,
      &:active {
        // border-bottom: 1px solid #4fafc2;
        text-decoration: underline;
      }
    }

    .btns {
      padding: 1.3em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        cursor: pointer;
        display: inline-block;
        width: 42px;
        height: 42px;
        border-radius: 30px;
        border: 2px solid #4fafc2;
        text-align: center;
        line-height: 42px;
        i {
          color: #4fafc2;
          font-size: 1.8em;
        }
        margin: 0 4px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>