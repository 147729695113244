// 快速发布
<template>
	<div class="quick">
		<div class="quick-submit">
			<div class="content bg-white rounded-3 px-4 py-3">
				<div class="px-1">
					<el-row :gutter="20">
						<el-col class="my-2" :xs="24" :sm="24" :md="7">
							<div>
								<el-row :gutter="15">
									<el-col :xs="12" :sm="24">
										<div
											class="big-btn"
											:class="{ active: navType === '1' }"
											@click="navType = '1'"
										>
											<h3>技术需求</h3>
											<p>发布您需要完成的工作</p>
										</div>
									</el-col>
									<el-col :xs="12" :sm="24">
										<div
											class="big-btn"
											:class="{ active: navType === '2' }"
											@click="navType = '2'"
										>
											<h3>产业需求</h3>
											<p>无论您有什么需求</p>
										</div>
									</el-col>

									<el-col :xs="12" :sm="24">
										<div
											class="big-btn"
											:class="{ active: navType === '3' }"
											@click="navType = '3'"
										>
											<h3>人才需求</h3>
											<p>无论您有什么需求</p>
										</div>
									</el-col>

									<el-col :xs="12" :sm="24">
										<div
											class="big-btn"
											:class="{ active: navType === '4' }"
											@click="navType = '4'"
										>
											<h3>投融资需求</h3>
											<p>无论您有什么需求</p>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-col>
						<el-col class="my-2" :xs="24" :sm="24" :md="17">
							<!-- 技术需求：软件、硬件 -->
							<div class="right-form-warp" v-show="navType === '1'">
								<el-row class="head" type="flex" justify="between">
									<el-col class="my-2" :xs="24" :sm="12">
										<h4 class="m-0">快速发布技术需求</h4>
									</el-col>
									<el-col class="my-2" :xs="24" :sm="12">
										<div class="right">
											<span>想找专业公司承接项目？</span>
										</div>
									</el-col>
								</el-row>

								<div class="form-wrap my-2">
									<el-form
										size="medium"
										label-width="110px"
										label-position="right"
									>
										<!-- 软件需求 -->
										<el-form-item label="需求名称：">
											<el-input
												style="width: 80%"
												placeholder="请输入你的需求名称"
											/>
										</el-form-item>

										<el-form-item label="所属领域：">
											<el-button type="primary">请选择类目</el-button>
										</el-form-item>

										<el-form-item label="开发终端：" v-if="demandType === '1'">
											<el-checkbox-group v-model="value">
												<el-checkbox
													v-for="city in plOptions"
													:label="city"
													:key="city"
													>{{ city }}</el-checkbox
												>
											</el-checkbox-group>
										</el-form-item>

										<!-- <el-form-item label="技术需求类型：">
                      <el-radio-group v-model="demandType">
                        <el-radio label="1">软件需求</el-radio>
                        <el-radio label="2">硬件需求</el-radio>
                      </el-radio-group>
                    </el-form-item> -->

										<el-form-item label="语言要求：">
											<el-checkbox-group v-model="value">
												<el-checkbox
													v-for="city in langOptions"
													:label="city"
													:key="city"
													>{{ city }}</el-checkbox
												>
											</el-checkbox-group>
										</el-form-item>

										<el-form-item label="是否需要源码：">
											<el-checkbox-group v-model="value">
												<el-checkbox
													v-for="city in originOptions"
													:label="city"
													:key="city"
													>{{ city }}</el-checkbox
												>
											</el-checkbox-group>
										</el-form-item>

										<el-form-item label="需求描述：">
											<div>
												<el-link type="primary" style="line-height: 1">
													不知道怎么写？看看他们是怎么发布的。
												</el-link>
											</div>

											<el-input
												type="textarea"
												:autosize="{ minRows: 6, maxRows: 8 }"
											/>

											<div class="tip">
												注意：需求发布通过审核之后，将会直接给您匹配相应的服务商。请不要把隐私信息、QQ号、微信号、电话号码等联系方式填写在需求中。
											</div>
										</el-form-item>

										<el-form-item label="添加附件：">
											<el-upload
												class="upload-demo"
												ref="upload"
												action="https://jsonplaceholder.typicode.com/posts/"
												:auto-upload="false"
											>
												<el-button slot="trigger" size="small" type="primary"
													>选取文件</el-button
												>
												<!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
												<div slot="tip" class="el-upload__tip">
													类型支持txt、ppt、pptx、doc、docx、xls、xlsx、pdf、png、jpg、jpeg，rar，附件大小不超过10M，最多不超过4个。
												</div>
											</el-upload>
										</el-form-item>

										<div class="other-form">
											<div class="fw-bold mt-3">其他偏好设置(选填)：</div>
											<div class="my-2">对服务商的地域有要求吗？</div>

											<el-form-item label-width="0">
												<el-radio-group>
													<el-radio :label="3">没有</el-radio>
													<el-radio :label="6">有</el-radio>
												</el-radio-group>
											</el-form-item>

											<el-form-item label="预算范围：">
												<el-input value="0" style="width: 100px" />

												<span style="margin: 0 4px"> 元 ~ </span>

												<el-input
													value="0"
													style="width: 100px; margin: 0 4px"
												/>

												<span>元</span>

												<el-checkbox style="margin-left: 20px" v-model="value"
													>可以议价</el-checkbox
												>
											</el-form-item>

											<el-form-item label="期望完成时间：">
												<el-date-picker type="date" placeholder="选择日期">
												</el-date-picker>
											</el-form-item>
										</div>
									</el-form>
								</div>

								<div class="other-form">
									<div class="my-2"></div>

									<div class="my-2">
										请留下您的联系方式，以便我们能及时联系到您
									</div>

									<div class="my-2">
										<el-input
											size="medium"
											placeholder="请输入您的手机号"
											style="width: 180px"
										/>

										<el-input
											size="medium"
											placeholder="请输入验证码"
											style="width: 120px; margin: 0 15px"
										/>

										<el-button size="medium" type="primary">
											立即获取
										</el-button>
									</div>

									<div class="my-2">
										<el-checkbox :value="true">我已阅读并同意 </el-checkbox>

										<el-link style="margin: 0 15px"
											>《平台发布需求规则》</el-link
										>

										<el-link>《用户协议与隐私政策》</el-link>
									</div>
									<div class="my-2">
										<el-button type="primary">确认无误，发布需求</el-button>
									</div>
								</div>
							</div>

							<div class="right-form-warp" v-show="navType === '2'">
								<el-row class="head" type="flex" justify="between">
									<el-col class="my-2" :xs="24" :sm="12">
										<h4 class="m-0">快速发布产业需求</h4>
									</el-col>
									<el-col class="my-2" :xs="24" :sm="12">
										<div class="right">
											<span>想找专业公司承接项目？</span>
										</div>
									</el-col>
								</el-row>
							</div>

							<div class="right-form-warp" v-show="navType === '3'">
								<el-row class="head" type="flex" justify="between">
									<el-col class="my-2" :xs="24" :sm="12">
										<h4 class="m-0">快速发布人才需求</h4>
									</el-col>
									<el-col class="my-2" :xs="24" :sm="12">
										<div class="right">
											<span>想找专业公司承接项目？</span>
										</div>
									</el-col>
								</el-row>

								<div class="my-2">
									<el-form
										label-width="100px"
										label-position="right"
										size="medium"
									>
										<div class="my-2 fw-bold">1.职位基本信息</div>
										<div class="tip">
											职位发布成功后，招聘类型、职位名称、职位类型、工作城市，将无法修改。
										</div>

										<el-form-item label="公司：">
											<span>XXX有限公司</span>
										</el-form-item>

										<el-form-item label="招聘类型：">
											<el-input style="width: 80%" />
										</el-form-item>

										<el-form-item label="职位名称：">
											<el-input style="width: 80%" />
										</el-form-item>

										<el-form-item label="职位描述：">
											<el-input
												:autosize="{ minRows: 6, maxRows: 10 }"
												type="textarea"
												style="width: 80%"
											/>
										</el-form-item>

										<el-form-item label="工作地点：">
											<el-input style="width: 80%" />
										</el-form-item>

										<el-form-item label="职位类型：">
											<el-input style="width: 80%" />
										</el-form-item>

										<div class="my-2 fw-bold">2.职位要求</div>

										<div class="tip mb-3">
											我们将通过以下条件，为您精确推荐合适的牛人，请尽量详细填写。
										</div>

										<el-form-item label="经验和学历：">
											<el-select
												v-model="value"
												placeholder="请选择"
												style="width: 130px"
											>
												<el-option
													v-for="item in options"
													:key="item.value"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
											<el-select
												v-model="value"
												placeholder="请选择"
												style="width: 130px; margin: 0 15px"
											>
												<el-option
													v-for="item in options"
													:key="item.value"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</el-form-item>

										<el-form-item label="薪资范围：">
											<div>
												<el-select
													v-model="value"
													placeholder="请选择"
													style="width: 130px"
												>
													<el-option
														v-for="item in options"
														:key="item.value"
														:label="item.label"
														:value="item.value"
													>
													</el-option>
												</el-select>
												<el-select
													v-model="value"
													placeholder="请选择"
													style="width: 130px; margin: 0 15px"
												>
													<el-option
														v-for="item in options"
														:key="item.value"
														:label="item.label"
														:value="item.value"
													>
													</el-option>
												</el-select>
												<span>X</span>
												<el-select
													v-model="value"
													placeholder="请选择"
													style="width: 130px; margin: 0 15px"
												>
													<el-option
														v-for="item in options"
														:key="item.value"
														:label="item.label"
														:value="item.value"
													>
													</el-option>
												</el-select>
											</div>
											<div class="tip">*请选择月薪</div>
										</el-form-item>

										<el-form-item label="职位关键词：">
											<el-select
												v-model="value"
												style="width: 80%"
												multiple
												placeholder="请选择"
											>
												<el-option
													v-for="item in options"
													:key="item.value"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</el-form-item>

										<el-form-item>
											<div class="text-right" style="width: 80%">
												简历将发送到 00000@outlook.com
												，如需更改邮箱，请在【个人中心】中修改
											</div>
										</el-form-item>

										<el-form-item>
											<div class="text-right" style="width: 80%">
												<el-link style="margin: 0 15px" :underline="false"
													>删除</el-link
												>

												<el-button>关闭</el-button>

												<el-button type="primary">保存并发布</el-button>
											</div>
										</el-form-item>
									</el-form>
								</div>
							</div>

							<div class="right-form-warp" v-show="navType === '4'">
								<el-row class="head" type="flex" justify="between">
									<el-col class="my-2" :xs="24" :sm="12">
										<h4 class="m-0">快速发布投融资需求</h4>
									</el-col>
									<el-col class="my-2" :xs="24" :sm="12">
										<div class="right">
											<span>想找专业公司承接项目？</span>
										</div>
									</el-col>
								</el-row>

								<el-form
									label-width="110px"
									label-position="right"
									size="medium"
								>
									<div class="fw-bold my-3">
										请介绍一下您的项目，以帮助我们了解它
									</div>

									<div class="my-2">基本信息</div>

									<el-form-item label="项目名称：">
										<el-input
											style="width: 80%"
											placeholder="50字以内，如：xxx"
										/>
									</el-form-item>

									<el-form-item label="一句话简介：">
										<el-input
											style="width: 80%"
											placeholder="请用一句话描述介绍您的项目，50字以内。尽量简洁有力"
											type="textarea"
											:autosize="{ minRows: 2, maxRows: 4 }"
										/>
									</el-form-item>

									<el-form-item label="行业/赛道：">
										<el-input
											style="width: 80%"
											placeholder="50字以内，如：xxx"
										/>
									</el-form-item>

									<el-form-item label="简要描述：">
										<el-input
											style="width: 80%"
											placeholder="尽可能简洁的说明产品定位、服务对象以及商业模式。500字内"
											type="textarea"
											:autosize="{ minRows: 6, maxRows: 8 }"
										/>
									</el-form-item>

									<div class="my-2">其他资料</div>

									<el-form-item label="企业主体：">
										<el-input
											style="width: 80%"
											placeholder="请填写所属企业的工商名称"
										/>
									</el-form-item>

									<el-form-item label="总部所在地：">
										<div style="width: 80%; display: flex">
											<div style="width: 40%; margin-right: 15px">
												<el-input placeholder="请填写所属企业的工商名称" />
											</div>
											<div style="width: 60%">
												<el-input placeholder="请填写所属企业的工商名称" />
											</div>
										</div>
									</el-form-item>

									<el-form-item label="项目网址：">
										<el-input style="width: 80%" placeholder="https://" />
									</el-form-item>

									<el-form-item label="微信公众号：">
										<el-input
											style="width: 80%"
											placeholder="输入公众号名称或ID，没有可不填。但项目网址和微信公众号至少填写一个"
										/>
									</el-form-item>

									<div class="fw-bold my-3">
										您项目的融资情况，对我们的报道和服务也十分重要
									</div>

									<div class="my-2">融资信息</div>

									<el-form-item label="本轮融资轮次：">
										<el-select
											style="width: 80%"
											v-model="value"
											placeholder="请选择"
										>
											<el-option
												v-for="item in options"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</el-form-item>

									<el-form-item label="本轮融资金额：">
										<div style="width: 80%; display: flex">
											<el-input
												style="width: 90%"
												placeholder="填写融资金额，并选择货币单位"
											/>
											<el-select
												style="width: 20%"
												v-model="moneyValue"
												placeholder="请选择"
											>
												<el-option
													v-for="item in money"
													:key="item.value"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</div>
									</el-form-item>

									<el-form-item label="本轮投资方：">
										<el-input
											style="width: 80%"
											placeholder="如有多家，请用逗号分隔"
										/>
									</el-form-item>

									<el-form-item label="融资完成时间：">
										<el-date-picker
											style="width: 80%"
											v-model="value1"
											type="date"
											placeholder="选择日期"
										>
										</el-date-picker>
									</el-form-item>

									<div class="my-2">融资计划</div>

									<el-form-item
										label="是否正在寻求下一轮融资："
										label-width="180px"
									>
										<el-radio-group v-model="value" style="width: 80%">
											<el-radio label="1">是，寻求融资</el-radio>
											<el-radio label="2">否，暂不需要</el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item label="本轮融资轮次：">
										<el-select
											style="width: 80%"
											v-model="value"
											placeholder="请选择"
										>
											<el-option
												v-for="item in options"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</el-form-item>

									<el-form-item label="本轮融资金额：">
										<div style="width: 80%; display: flex">
											<el-input
												style="width: 90%"
												placeholder="填写融资金额，并选择货币单位"
											/>
											<el-select
												style="width: 20%"
												v-model="moneyValue"
												placeholder="请选择"
											>
												<el-option
													v-for="item in money"
													:key="item.value"
													:label="item.label"
													:value="item.value"
												>
												</el-option>
											</el-select>
										</div>
									</el-form-item>

									<div class="my-3 fw-bold">
										请描述一下您的项目亮点和优势，同时可上传附件做更细致的说明
									</div>

									<div class="my-2">项目亮点（至少填写一个项）</div>

									<el-form-item label="发展数据：">
										<el-input
											type="textarea"
											style="width: 80%"
											placeholder="请简述项目发展阶段的各方面数据，包括但不限于商业或用户数据。500字以内"
											:autosize="{ minRows: 6, maxRows: 8 }"
										/>
									</el-form-item>

									<el-form-item label="竞争壁垒：">
										<el-input
											type="textarea"
											style="width: 80%"
											placeholder="请简述项目的护城河，或者已经建立的竞争壁垒。500字以内"
											:autosize="{ minRows: 6, maxRows: 8 }"
										/>
									</el-form-item>

									<el-form-item label="团队构成：">
										<el-input
											type="textarea"
											style="width: 80%"
											placeholder="如果团队背景对该项目有明显推动作用，请说明具体优势。500字以内"
											:autosize="{ minRows: 6, maxRows: 8 }"
										/>
									</el-form-item>

									<el-form-item>
										<div style="width: 80%; text-align: center">
											<el-button style="width: 140px" type="primary"
												>提交申请</el-button
											>
										</div>
									</el-form-item>
								</el-form>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'QuickSubmit',
	data() {
		return {
			showSimple: false,

			// 1 2 3 4
			navType: '4',

			// 技术需求类型：1软件 2硬件
			demandType: '1',

			options: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			value: '',

			plOptions: ['Web', 'Andioid', 'iOS', 'WeChat', 'H5', 'Other'],
			langOptions: ['无要求', '有语言要求'],

			originOptions: ['需要', '不需要'],

			money: [
				{
					value: '选项1',
					label: '人民币',
				},
				{
					value: '选项2',
					label: '美元',
				},
			],
			moneyValue: '选项1',

			value1: '',
		}
	},
}
</script>

<style lang="scss" scoped>
.quick {
  background: #4fafc2;
	.quick-submit {
		padding-bottom: 1.5rem;
		margin: auto;
		width: 1200px;
		.big-btn {
			// background: #4fafc2;
			// color: #fff;

			transition: all 0.15s;

			background: #fff;
			border: 1px solid #4fafc2;
			color: #4fafc2;

			cursor: pointer;
			border-radius: 0.2rem;
			padding: 0.2rem 0.5rem;
			margin: 1rem 0;
			text-align: center;
			&:hover,
			&:active,
			&.active {
				box-shadow: 2px 2px 2px #ccc;
				transform: translateY(-3px);

				background: #4fafc2;
				color: #fff;
			}

			h3 {
				// color: #fff;
				font-size: 1.2rem;
				font-weight: 500;
				line-height: 1.5em;
				margin-top: 20px;
				margin-bottom: 10px;
			}

			p {
				margin: 0.2rem 0;
				font-size: 0.9em;
			}
		}

		.right-form-warp {
			.head {
				h4 {
					font-weight: 500;
					font-size: 1.2rem;
				}
				.right {
					font-size: 0.8rem;
					cursor: pointer;
					text-align: right;
					color: #337ab7;
					span:hover {
						opacity: 1;
						background: #4fafc2;
						color: #fff;
						text-decoration: none;
					}
				}
			}

			.tip {
				font-size: 14px;
				line-height: 1.5;
				color: #999;
			}
			.other-form {
				font-size: 14px;
			}
		}

		.simple-desc {
			font-size: 0.85rem;
			padding: 0.2rem;
			color: #555;
			p {
				margin-bottom: 0.3rem;
			}
		}
	}
}
</style>

<style lang="scss">
.quick-submit {
	.el-upload-dragger {
		height: 40px;
		.el-icon-upload {
			color: #c0c4cc;
			margin: 0;
			font-size: 1.8rem;
			line-height: 40px;
			vertical-align: middle;
		}
		.el-upload__text {
			display: inline-block;
			line-height: 40px;
			vertical-align: middle;
			padding-left: 0.5rem;
		}
	}
}
</style>
