<template>
  <div class="about-us">
    <r-row>
      <div class="head">
        <h3>关于我们</h3>
        <p>Ashley is a versatile one page website template</p>
        <span></span>
      </div>
    </r-row>

    <r-row>
      <el-row :gutter="30">
        <r3-1>
          <div class="item hidden-sm-and-down">
            <img src="@/assets/images/abt_pic.jpg" alt="" srcset="" />
          </div>
        </r3-1>
        <r3-1>
          <div class="item">
            <h5>Build your website, now!</h5>
            <p>
              This did not seem to encourage the witness at all: he kept
              shifting from one foot to the other, looking uneasily at the
              Queen, and in his confusion he bit a large piece out of his teacup
              instead of the bread-and-butter.
            </p>
            <p>
              Just at this moment Alice felt a very curious sensation, which
              puzzled her a good deal until she made out what it was: she was
              beginning to grow larger again, and she thought at first she would
              get up and leave the court; but on second thoughts she decided to
              remain where she was as long as there was room for her.
            </p>
          </div>
        </r3-1>
        <r3-1>
          <div class="item">
            <h5>Ashley website template</h5>
            <p>
               This did not seem to encourage the witness at all: he kept shifting from one foot to the other, looking uneasily at the Queen, and in his confusion he bit a large piece out of his teacup instead of the bread-and-butter. 
            </p>
            <p>
               Just at this moment Alice felt a very curious sensation, which puzzled her a good deal until she made out what it was: she was beginning to grow larger again, and she thought at first she would get up and leave the court; but on second thoughts she decided to remain where she was as long as there was room for her. 
            </p>
          </div>
        </r3-1>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import RRow from "../../components/RRow.vue";
export default {
  components: { RRow },
  name: "AboutUs",
};
</script>

<style lang="scss" scoped>
.about-us {
  padding: 50px 0;
  background-color: #fff;
  .head {
    text-align: center;
    margin-bottom: 4rem;
    h3 {
      color: #333333;
      font-size: 3.4em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 5px;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      color: #888888;
      font-size: 1.65em;
      font-weight: 300;
      margin: 0.5em 0 0.7em 0;
    }
    span {
      height: 1px;
      width: 10%;
      background: #4fafc2;
      color: #4fafc2;
      display: inline-block;
    }
  }

  .item {
    padding-bottom: 30px;
    color: #888;
    img {
      width: 100%;
    }
    h5 {
      font-size: 1.5rem;
    }
  }
}
</style>