<template>
<div class="home-banner-com">
  <r-row>
    <el-row :gutter="15" class="content-wrap"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <el-col
        :xs="16" 
        :sm="14" 
        :md="14" 
      >
      <div class="swiper-wrap">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide class="swiper-slide">
            <div class="slogan">
              <h2>
                您好，这是
                <br />项目发布平台
              </h2>
              <p>也可以选择您的自由者</p>
            </div>
          </swiper-slide>
          <swiper-slide  class="swiper-slide">
            <div class="slogan">
              <h2>
                您好，这是
                <br />项目发布平台
              </h2>
              <p>也可以选择您的自由者</p>
            </div>
          </swiper-slide>
          <swiper-slide  class="swiper-slide">
            <div class="slogan">
              <h2>
                您好，这是
                <br />项目发布平台
              </h2>
              <p>也可以选择您的自由者</p>
            </div>
          </swiper-slide>
         <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      </el-col>
      <el-col
        :xs="8" 
        :sm="10" 
        :md="10" 
      >
        <div class="banner-right">
        <img src="@/assets/images/lady.png" alt />
        </div>
      </el-col>
    </el-row>
  </r-row>
</div>
</template>

<script>
import RRow from '../../components/RRow.vue'
export default {
  components: { RRow },
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs['swiper']
    }
  },
  mounted () {
    console.log('swiper', this.swiper)
  }
}
</script>

<style lang="scss">
.home-banner-com {
  // height: 500px;
  // overflow: hidden;
  background: url('../../assets/images/slider_bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .content-wrap {
    padding-top: 30px;
    padding-right: 20px;
  }

  .swiper-wrap {
    // padding: 20px 0;
    display: flex;
    align-items: center;
    .swiper-slide {
      padding: 30px;
    }
    .slogan {
      color: #fff;
    }
    .slogan h2{
      font-size: 4.5rem;
      font-weight: 300;
      letter-spacing: 0.1em;
      line-height: 1.1;
      font-family: inherit;
      margin-bottom: 20px;
    }
    .slogan p {
      font-size: 1.1rem;
      margin: 2rem 0;
    }
  }

  .banner-right {
    // flex: 0 0 auto;
    // width: 60%;
    img {
      // height: 470px;
      width: 100%;
    }
  }


}
</style>