<template>
  <div class="skills">
    <r-row>
      <h3>顶尖技能</h3>
      <el-row 
      >
        <el-col 
          :xs="24" 
      :sm="12" 
      :md="6" 
      :lg="6"
      :xl="6"
          v-for="item in skills"
          :key="item"
        >
          <el-link class="item">{{ item }}</el-link>
        </el-col>
      </el-row>
      <div class="mt-2">
        <el-link type="primary">Show more</el-link>
      </div>
    </r-row>
  </div>
</template>

<script>
export default {
  name: 'Skills',
  data () {
    return {
      skills: [
        'Android开发人员',
        '会计',
        '内容作家',
        '撰稿人',
        '客户服务代表',
        '数据库管理员',
        '数据科学家',
        'Facebook开发人员',
        '前端开发人员',
        '游戏开发者',
        '平面设计师',
        '信息安全分析师',
        'iOS开发人员',
        'Java开发人员',
        'JavaScript开发人员',
        '徽标设计师',
        '行动应用程式开发人员',
        'PHP开发人员',
        'Python开发人员',
        '简历作家',
        '销售顾问',
        'SEO专家',
        '社交媒体经理',
        '软件开发人员',
        '软件工程师',
        '技术文件撰稿人',
        'UI设计师',
        '用户体验设计师',
        '虚拟助手',
        '网页设计师',
        'WordPress开发人员',
        '作家',
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.skills {
  padding: 5em 0;
  background-color: #f8f8f8;
  h3 {
    margin: 20px 0;
  }
  .item {
    margin: 4px 0;
  }
}
</style>