<template>
  <div class="our-scheme">
    <r-row>
      <div class="head">
        <h3>我们的方案</h3>
        <span></span>
      </div>
    </r-row>

    <r-row>
      <el-row :gutter="30">
        <r3-1>
          <div class="item">
            <div class="title">
              <h3>
                基础版
                <span>-￥</span>
                500元
              </h3>
            </div>
            <div class="content">
              <ul>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  1、经过验证的工作历史和评论</li>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  2、无限的提案</li>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  3、内置的协作工具和便捷的付款方式</li>
              </ul>
            </div>
            <div class="btn">
              <el-button type="primary">选择</el-button>
            </div>
          </div>
        </r3-1>
        <r3-1>
          <div class="item">
            <div class="title">
              <h3>
                基础版
                <span>-￥</span>
                500元
              </h3>
            </div>
            <div class="content">
              <ul>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  1、经过验证的工作历史和评论</li>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  2、无限的提案</li>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  3、内置的协作工具和便捷的付款方式</li>
              </ul>
            </div>
            <div class="btn">
              <el-button type="primary">选择</el-button>
            </div>
          </div>
        </r3-1>
        <r3-1>
          <div class="item">
            <div class="title">
              <h3>
                基础版
                <span>-￥</span>
                500元
              </h3>
            </div>
            <div class="content">
              <ul>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  1、经过验证的工作历史和评论</li>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  2、无限的提案</li>
                <li>
                  <i class="iconfont icon-zhengque-correct"></i>
                  3、内置的协作工具和便捷的付款方式</li>
              </ul>
            </div>
            <div class="btn">
              <el-button type="primary">选择</el-button>
            </div>
          </div>
        </r3-1>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import RRow from "../../components/RRow.vue";
export default {
  components: { RRow },
  name: "OutScheme",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/element.scss';
.our-scheme {
  padding: 50px 0;
  background-color: #f8f8f8;
  .head {
    text-align: center;
    margin-bottom: 2rem;
    h3 {
      color: #333333;
      font-size: 3.4em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 5px;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      color: #888888;
      font-size: 1.65em;
      font-weight: 300;
      margin: 0.5em 0 0.7em 0;
    }
    span {
      height: 1px;
      width: 10%;
      background: #4fafc2;
      color: #4fafc2;
      display: inline-block;
    }
  }

  .item {
    cursor: pointer;
    background: #fff;
    margin-top: 2rem;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    .title {
      padding: 30px;
      border-bottom: 1px solid #e0e0e0;
      h3 {
        font-size: 20px;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #bfc1c7;
        }
      }
    }

    &:hover {
      transform: translateY(-5px);
    }

    .content {
          height: 150px;
      margin-top: 10px;
      margin-bottom: 10px;
        padding: 0 25px;
        // height: 150px;
        ul {
          list-style: none;
          padding: 0;
          li {
            i {
              color: $--color-primary;
              font-size: 1.5em;
              // vertical-align: middle;
            }
                line-height: 35px;
    position: relative;
    font-size: 13px;
    color: #81848f;
          }
        }
    }

    .btn {
      text-align: center;
      padding: 35px 0;
    }
  }
}
</style>