<template>
  <div class="step-process  hidden-sm-and-down">
    <r-row>
      <div class="head">
        <h3>软件项目交易网交易流程</h3>
        <span></span>
      </div>
      <div class="d-flex justify-content-between align-items-start">
        <div class="item">
          <h3>免费发布项目</h3>
          <span>为了保证项目的真实性和合法性，请您认真填写项目需求，不合规的需求信息将被做违规处理。</span>
        </div>
        <div class="svg-wrap">
          <svg
            t="1574751189592"
            class="icon line"
            viewBox="0 0 3294 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2825"
            width="80"
            height="60"
          >
            <path
              d="M0 578.783h489.74V445.217H0v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.347 0h489.74V445.217h-489.74v133.566z m948.937 0H2849.39V445.217h217.222l-303.594-303.549a66.783 66.783 0 0 1 94.43-94.43l409.245 409.243a71.952 71.952 0 0 1 4.007 4.319 66.649 66.649 0 0 1 15.404 83.79c-1.158 15.404-7.569 30.497-19.411 42.296L2857.45 996.129a66.783 66.783 0 0 1-94.43-94.43l322.96-322.916z"
              fill="#4fafc2"
              p-id="2826"
            />
          </svg>
        </div>

                <div class="item">
          <h3>接包方竞标</h3>
          <span>参与竞标需要支付2元竞标费，购买竞标卡更优惠，竞标后请主动联系发包方了解需求。</span>
        </div>
        <div class="svg-wrap">
          <svg
            t="1574751189592"
            class="icon line"
            viewBox="0 0 3294 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2825"
            width="80"
            height="60"
          >
            <path
              d="M0 578.783h489.74V445.217H0v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.347 0h489.74V445.217h-489.74v133.566z m948.937 0H2849.39V445.217h217.222l-303.594-303.549a66.783 66.783 0 0 1 94.43-94.43l409.245 409.243a71.952 71.952 0 0 1 4.007 4.319 66.649 66.649 0 0 1 15.404 83.79c-1.158 15.404-7.569 30.497-19.411 42.296L2857.45 996.129a66.783 66.783 0 0 1-94.43-94.43l322.96-322.916z"
              fill="#4fafc2"
              p-id="2826"
            />
          </svg>
        </div>

                <div class="item">
          <h3>开始项目</h3>
          <span>发包方全额托管项目款，项目进入开发阶段，项目开发完成前，您的资金会安全地托管在平台上。</span>
        </div>
        <div class="svg-wrap">
          <svg
            t="1574751189592"
            class="icon line"
            viewBox="0 0 3294 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2825"
            width="80"
            height="60"
          >
            <path
              d="M0 578.783h489.74V445.217H0v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.347 0h489.74V445.217h-489.74v133.566z m948.937 0H2849.39V445.217h217.222l-303.594-303.549a66.783 66.783 0 0 1 94.43-94.43l409.245 409.243a71.952 71.952 0 0 1 4.007 4.319 66.649 66.649 0 0 1 15.404 83.79c-1.158 15.404-7.569 30.497-19.411 42.296L2857.45 996.129a66.783 66.783 0 0 1-94.43-94.43l322.96-322.916z"
              fill="#4fafc2"
              p-id="2826"
            />
          </svg>
        </div>

                <div class="item">
          <h3>支付项目款</h3>
          <span>在项目开发完成、发包方验收合格后，方可支付项目款，平台会扣取项目款5%作为服务费。</span>
        </div>
        <div class="svg-wrap">
          <svg
            t="1574751189592"
            class="icon line"
            viewBox="0 0 3294 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2825"
            width="80"
            height="60"
          >
            <path
              d="M0 578.783h489.74V445.217H0v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.348 0h489.739V445.217h-489.74v133.566z m712.347 0h489.74V445.217h-489.74v133.566z m948.937 0H2849.39V445.217h217.222l-303.594-303.549a66.783 66.783 0 0 1 94.43-94.43l409.245 409.243a71.952 71.952 0 0 1 4.007 4.319 66.649 66.649 0 0 1 15.404 83.79c-1.158 15.404-7.569 30.497-19.411 42.296L2857.45 996.129a66.783 66.783 0 0 1-94.43-94.43l322.96-322.916z"
              fill="#4fafc2"
              p-id="2826"
            />
          </svg>
        </div>

                <div class="item">
          <h3>双方互评</h3>
          <span>项目完工后双方可以互相评价，良好的评价会为您带来更多的合作机会。</span>
        </div>

      </div>
    </r-row>
  </div>
</template>

<script>
export default {
  name: 'StepProcess'
}
</script>

<style lang="scss" scoped>
  .step-process {
        padding: 50px 0;
  background-color: #fff;
  .head {
    text-align: center;
    margin-bottom: 4rem;
    h3 {
      color: #333333;
      font-size: 3.4em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 5px;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      color: #888888;
      font-size: 1.65em;
      font-weight: 300;
      margin: 0.5em 0 0.7em 0;
    }
    span {
      height: 1px;
      width: 10%;
      background: #4fafc2;
      color: #4fafc2;
      display: inline-block;
    }
  }

  .item {
    text-align: center;
    h3 {
      margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    color: rgb(20, 20, 20);
    font-family: '微软雅黑';
    }
    span {
      text-align: justify;
      display: inline-block;
      font-size: 12px;
    color: rgb(102, 102, 102);
    font-family: '微软雅黑';
    }


  }

      .svg-wrap {
      padding-top: 44px;
      padding-left: 15px;
      padding-right: 15px;
    }

  }
</style>