<template>
  <div class="search-pan">
    <r-row>
      <div class="content bg-white rounded-3 px-4 py-3">
        <div class="px-2">
          <el-row :gutter="15">
          <el-col class="my-2"
            :xs="{ span: 22, offset: 1 }" 
            :sm="15" 
            :md="17" 
          >
            <el-input class="big-input"
              placeholder="搜索自由职业者"
            ></el-input>
          </el-col>
          <el-col class="my-2"
            :xs="{ span: 22, offset: 1 }" 
            :sm="9" 
            :md="7" 
          >
            <el-button type="primary"
              style="width: 100%;"
              class="big-btn"
            >立即搜索</el-button>
          </el-col>
        </el-row>

        <div class="my-1">
          <span class="cat-title">
            <i class="iconfont icon-fire"></i>
            <span>大家都在搜索</span>
          </span>
          <a href="javascript:;" class="cat-name">方案及设计</a>
          <a href="javascript:;" class="cat-name">pcb及pcba</a>
          <a href="javascript:;" class="cat-name">治具</a>
          <a href="javascript:;" class="cat-name">工装</a>
          <a href="javascript:;" class="cat-name">夹具</a>
          <a href="javascript:;" class="cat-name">钢网</a>
          <a href="javascript:;" class="cat-name">模具</a>
          <a href="javascript:;" class="cat-name">注塑</a>
          <a href="javascript:;" class="cat-name">嵌入式开发</a>
          <a href="javascript:;" class="cat-name">软件开发</a>
          <a href="javascript:;" class="cat-name">商品</a>
          <a href="javascript:;" class="cat-name">设备</a>
          <a href="javascript:;" class="cat-name">材料</a>
          <a href="javascript:;" class="cat-name">配件</a>
          <a href="javascript:;" class="cat-name">服务</a>
          <a href="javascript:;" class="cat-name">创新小镇</a>
        </div>
        </div>
      </div>
    </r-row>
  </div>
</template>

<script>
import RRow from '../../components/RRow.vue'
export default {
  components: { RRow },
  data () {
    return {
      active: 0
    }
  }
}
</script>

<style lang="scss">
  .search-pan {
    background: #4fafc2;
    padding: 1.5rem 0;
    .big-input {
      .el-input__inner {
        height: 46px;
        line-height: 46px;
      }
    }
    .big-btn {
      font-size: 16px;
      padding: 14px 20px;
    }

    .cat-title {
      display: inline-block;
      margin-right: 10px;
      color: #666;
      font-size: 0.9em;
      i {
        color: red;
        font-size: 1rem;
        padding-right: 0.1em;
      }
    }

    .cat-name {
      display: inline-block;
      padding: 5px 15.5px;
      background-color: #f8f8f8;
      border-radius: 20px;
      font-size: 12px;
      color: #888;
      text-align: center;
	// margin-left: 12.5px;

    &:hover {
      background-color: #4fafc2;
      color: #fff;
      text-decoration: none;
    }

     margin: 3px 5px;

    }

    


  }
</style>