<template>
  <div class="inpeak-home">
    <banner />
    <search-pan />

    <quick-submit />

    <select-type />

    <server-desc />

    <about-us />

    <re-project />

    <step-process />

    <our-scheme />

    <our-team />

    <skills />

  </div>
</template>

<script>
import Banner from './Banner.vue'
import QuickSubmit from './QuickSubmit.vue'
import SearchPan from './searchPan.vue'
import SelectType from './selectType.vue'
import ServerDesc from './serverDesc.vue'
import AboutUs from './AboutUs'
import ReProject from './ReProject'
import StepProcess from './stepProcess.vue'
import OurScheme from './ourScheme.vue'
import OurTeam from './ourTeam'
import Skills from './skills.vue'
export default {
  name: 'home',
  components: {
    Banner,
    SearchPan,
    QuickSubmit,
    SelectType,
    ServerDesc,
    AboutUs,
    ReProject,
    StepProcess,
    OurScheme,
    OurTeam,
    Skills
  },
  data ( ){
    return {
      headers: {}
    }
  },
  methods: {

    beforeRemove () {

    },
    httpRequest () {

    },
    fileBroadcastChange () {

    }
  }
}
</script>

<style>

</style>