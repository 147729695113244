<template>
  <div class="select-type">
    <r-row>
      <div class="head">
        <h3>选择应用类型，快速发布</h3>
        <p>查找您的自由职业者或代理商</p>
        <span></span>
      </div>
    </r-row>
    <r-row>
      <el-row :gutter="30">
        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>网络，移动和软件开发</span>
          </div>
        </r4-1>
        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>设计与创意</span>
          </div>
        </r4-1>
        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>写作 </span>
          </div>
        </r4-1>
        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>销售与市场营销 </span>
          </div>
        </r4-1>

        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>管理员支持 </span>
          </div>
        </r4-1>

        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>客户服务 </span>
          </div>
        </r4-1>

        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>数据科学与分析 </span>
          </div>
        </r4-1>

        <r4-1 class="my-2">
          <div class="item">
            <img src="@/assets/images/security.jpg" alt="" />
            <span>工程与建筑 </span>
          </div>
        </r4-1>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import RRow from "../../components/RRow.vue";
export default {
  components: { RRow },
};
</script>

<style lang="scss" scoped>
.select-type {
  padding: 50px 0;
  background-color: #fff;
  .head {
    text-align: center;
    margin-bottom: 4rem;
    h3 {
      color: #333333;
      font-size: 3.4em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 5px;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      color: #888888;
      font-size: 1.65em;
      font-weight: 300;
      margin: 0.5em 0 0.7em 0;
    }
    span {
      height: 1px;
      width: 10%;
      background: #4fafc2;
      color: #4fafc2;
      display: inline-block;
    }
  }

  .item {
    margin-bottom: 1.5rem;
    cursor: pointer;
    color: #23527c;
    border-radius: 2px;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    text-decoration: none;
    position: relative;
    overflow: hidden;
    display: block;
    &:hover,
    &:active {
      transform: translateY(-5px);
      text-decoration: none;
    }
    img {
      width: 100%;
      height: 130px;
    }
    span {
      width: 100%;
      text-align: center;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      padding: 1.5rem 0;
    }
  }
}
</style>