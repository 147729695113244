<template>
  <div class="re-project">
    <r-row>
      <div class="head">
        <h3>项目推荐</h3>
        <p>我们一直在进行的最新酷项目</p>
        <span></span>
      </div>
    </r-row>
    <r-row>
      <el-row :gutter="30">
        <r3-1 v-for="i in 4" :key="i">
          <div class="item">
            <img src="@/assets/images/n1.jpg" alt="" srcset="">
            <div class="desc-wrap">
              <div class="fs-4 m-2">项目名称</div>
              <div class="fs-6 text-black-50 m-2">开发app >> 1个月</div>
              <div class="fs-6 text-black-50 m-2">
                <span>
                  预算：<span class="text-danger">500000</span>
                </span>
              </div>
            </div>
          </div>
        </r3-1>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import RRow from '../../components/RRow.vue'
export default {
  components: { RRow },
  name: 'ReProject'
}
</script>

<style lang="scss" scoped>
  .re-project {
        padding: 50px 0;
  background-color: #f8f8f8;
  .head {
    text-align: center;
    margin-bottom: 4rem;
    h3 {
      color: #333333;
      font-size: 3.4em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 5px;
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
    }

    p {
      color: #888888;
      font-size: 1.65em;
      font-weight: 300;
      margin: 0.5em 0 0.7em 0;
    }
    span {
      height: 1px;
      width: 10%;
      background: #4fafc2;
      color: #4fafc2;
      display: inline-block;
    }
  }

    .item {
      padding-bottom: 30px;
      img {
        width: 100%;
      }
      .desc-wrap {
        border: 1px solid transparent;
        background: #fff;
      }
    }

  }
</style>